import React, { Component } from "react";
import Footer from "./Footer";
import Main from "./Main";
import BrandSignup from "../BrandSignup";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.talentFormRef = React.createRef();
    this.state = {
      isOpenBrandSignup: false,
    };
  }

  componentDidMount() {
    window.addEventListener("keyup", this.listenKeyboard, true);
  }

  listenKeyboard = (event) => {
    return (
      (event.key === "Escape" || event.keyCode === 27) &&
      this.setState({ isOpenBrandSignup: false })
    );
  };

  scrollToBottom = () => {
    this.talentFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  handleOpenBrandSignup = () => {
    this.setState({
      isOpenBrandSignup: !this.state.isOpenBrandSignup,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Main
          handleScrollBottom={this.scrollToBottom}
          handleOpenBrandSignup={this.handleOpenBrandSignup}
        />
        <Footer bottomRef={this.talentFormRef} />
        {this.state.isOpenBrandSignup && (
          <BrandSignup
            openBrandSignup={this.handleOpenBrandSignup}
            isOpenBrandSignup={this.state.isOpenBrandSignup}
          />
        )}
      </React.Fragment>
    );
  }
}
