import React from "react";

import logo from "../../../assets/images/schmodelplain-withR.png";
import styles from "./styles.module.css";

import StickyHeader from "./StickyHeader";

export default class Main extends React.Component {
  render() {
    return (
      <>
        <StickyHeader>
          <div className={`${styles.header_link} `}>
            <button
              className={`${styles.header_link_styles} ${
                styles.header_link_child_1
              }`}
              onClick={this.props.handleScrollBottom}
            >
              I AM TALENT
            </button>
            <button
              className={styles.header_link_styles}
              onClick={this.props.handleOpenBrandSignup}
            >
              I'M A BRAND
            </button>
          </div>
        </StickyHeader>

        <div className={styles.main}>
          <div className={`${styles.banner} ${styles.first_banner}`}>
            <div className={styles.is_desktop}>
              <div className={styles.header_logo_wapper}>
                <div className={styles.header_logo}>
                  <img src={logo} alt="error" className={styles.for_desktop} />
                </div>
                <div className={styles.header_cylinder} />
              </div>
            </div>

            <div className={styles.is_mobile}>
              <div className={styles.header_cylinder}>
                <div className={styles.header_logo}>
                  <img
                    src={logo}
                    alt="error"
                    className={styles.for_mobile}
                  />
                </div>
              </div>
            </div>
          </div>

        </div>
      </>
    );
  }
}
